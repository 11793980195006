import { Box, Divider, Flex, Rating, Text } from "@mantine/core";
import { ImageComponent } from "./image.component";

export const ConfirmHotel = ({ hotelData, isMd, utils, GlobalCard }) => {
  return (
    <Box>
      <Text component="h3" fw={700} size="xl" color="#004236" mt={0}>
        Hotel
      </Text>
      {hotelData.map((item, index) => (
        <GlobalCard key={index}>
          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            mih={isMd ? "auto" : "208px"}
          >
            <ImageComponent
              src={item?.imagenServicio}
              alt={item?.imagenServicio}
              radius="md"
              height={240}
              sx={{ flex: 1 }}
              defaultWidth="50%"
            />

            <Divider
              orientation={isMd ? "horizontal" : "vertical"}
              sx={{
                width: isMd ? "100%" : "1px",
              }}
            />
            <Box sx={{ flex: 1 }} ta="center">
              <Text fw={700} c="#004236">
                {item?.nombreServicio}
              </Text>
              <Text>{JSON.parse(item?.descripcionServicio)?.description}</Text>
              <Text ta="center" fw={700} c="#004236">{`${
                JSON.parse(item?.descripcionServicio)?.origin
              } - ${JSON.parse(item?.descripcionServicio)?.destination}`}</Text>
              <Flex justify="center" gap={12}>
                <Box>
                  <Text fw={700} c="#004236" ta="center">
                    Fecha inicio:
                  </Text>
                  <Text ta="center">
                    {utils.parseDateLocaleFormat(
                      JSON.parse(item?.descripcionServicio)?.fechaInicio
                    )}
                  </Text>
                </Box>
                <Box>
                  <Text fw={700} c="#004236" ta="center">
                    Fecha fin:
                  </Text>
                  <Text ta="center">
                    {utils.parseDateLocaleFormat(
                      JSON.parse(item?.descripcionServicio)?.fechaFin
                    )}
                  </Text>
                </Box>
              </Flex>
              <Rating
                defaultValue={item.estrellasHotel.split(" ")[0]}
                readOnly
                mx="auto"
              />
            </Box>
          </Flex>
        </GlobalCard>
      ))}
    </Box>
  );
};
