import { Flex, Grid, SimpleGrid, Text } from "@mantine/core";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { ImageComponent } from "./image.component";

export const ConfirmFlight = ({
  data,
  validationData,
  GlobalCard,
  utils,
  isMd,
}) => {
  const getFlightData = data?.map((item) =>
    JSON.parse(item.descripcionServicio)
  );

  const spliceInternationalData = validationData
    ? getFlightData
    : [
        { fligths: getFlightData[0]?.departureFlight?.flightSegments },
        { fligths: getFlightData[0]?.returnsFlight?.flightSegments },
      ];

  return (
    <>
      <Text component="h3" fw={700} size="xl" color="#004236" mt={0}>
        Vuelos
      </Text>
      <GlobalCard styles={{ minHeight: "280px" }}>
        <Flex
          align="center"
          wrap="wrap"
          justify="center"
          rowGap={12}
          h="100%"
          sx={(theme) => ({
            [theme.fn.smallerThan("md")]: {
              flexDirection: "column",
            },
          })}
        >
          {spliceInternationalData?.map((item, index) =>
            item.fligths.map((fligth, fligthIndex) => (
              <Grid.Col
                key={fligthIndex}
                span="content"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  flex: 1,
                  borderBottom:
                    index < spliceInternationalData?.length - 1
                      ? "1px solid rgb(203, 211, 0)"
                      : "none",
                  flexBasis: !isMd
                    ? `${Math.floor(100 / item.fligths.length)}%`
                    : "100%",
                }}
              >
                <Flex direction="column" gap="4px" align="center">
                  <Flex align="center" gap="8px" justify="center">
                    <ImageComponent
                      src={fligth.image}
                      alt={fligth.image}
                      width="50px"
                      height="50px"
                      fit="cover"
                    />

                    <Text fw="700" c="#004236">
                      {fligth.nameAirLine}
                    </Text>
                  </Flex>
                  <Flex align="center" gap="8px" justify="center">
                    <SimpleGrid
                      cols={1}
                      spacing="xs"
                      verticalSpacing="xs"
                      ta="center"
                    >
                      <Text span fw="700" c="#004236" ta="center">
                        {fligth.departureCity}
                      </Text>
                      <Text span size="sm" mt="-8px">
                        {`Fecha: ${utils.parseDateWihtFormat(
                          fligth.departureDateTime,
                          "YYYY-MM-DD"
                        )}`}
                      </Text>
                      <Text span size="sm" mt="-8px">
                        {`Hora Salida : ${utils.extractTime(
                          fligth.departureDateTime,
                          "HH:mm"
                        )}`}
                      </Text>
                    </SimpleGrid>
                    <BiSolidPlaneAlt size="1.5rem" color="#004236" />
                    <SimpleGrid
                      cols={1}
                      spacing="xs"
                      verticalSpacing="xs"
                      ta="center"
                    >
                      <Text span fw="700" c="#004236" ta="center">
                        {fligth.arrivalCity}
                      </Text>
                      <Text span size="sm" mt="-8px">
                        {`Fecha: ${utils.parseDateWihtFormat(
                          fligth.arrivalDateTime,
                          "YYYY-MM-DD"
                        )}`}
                      </Text>
                      <Text span size="sm" mt="-8px">
                        {`Hora Llegada: ${utils.extractTime(
                          fligth.arrivalDateTime,
                          "HH:mm"
                        )}`}
                      </Text>
                    </SimpleGrid>
                  </Flex>
                </Flex>
              </Grid.Col>
            ))
          )}
        </Flex>
      </GlobalCard>
    </>
  );
};
