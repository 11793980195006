import { Box, Divider, Flex, Text } from "@mantine/core";
import { ImageComponent } from "./image.component";

export const ConfirmService = ({ data, GlobalCard, isMd, utils, title }) => {
  return (
    <Box>
      <Text component="h3" fw={700} size="xl" color="#004236" mt={0}>
        {title}
      </Text>
      {data?.map((item, index) => (
        <GlobalCard key={index} styles={{ height: isMd ? "auto" : "280px" }}>
          <Flex
            gap={12}
            align="center"
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                flexDirection: "column",
              },
            })}
            h="100%"
          >
            <ImageComponent
              src={item.imagenServicio}
              alt={item.imagenServicio}
              radius="md"
              height={240}
              sx={{ flex: 1 }}
              fit="contain"
              defaultWidth="50%"
            />
            <Divider
              orientation={isMd ? "horizontal" : "vertical"}
              sx={{
                width: isMd ? "100%" : "1px",
              }}
            />
            <Box sx={{ flex: 1 }} ta="center">
              <Text fw={700} c="#004236">
                {item.nombreServicio}
              </Text>
              <Flex justify="center" gap={12}>
                <Box>
                  <Text fw={700} c="#004236" ta="center">
                    Fecha inicio:
                  </Text>
                  <Text ta="center">
                    {utils.parseDateLocaleFormat(
                      JSON.parse(item?.descripcionServicio)?.initialDate
                    )}
                  </Text>
                </Box>
                <Box>
                  <Text fw={700} c="#004236" ta="center">
                    Fecha fin:
                  </Text>
                  <Text ta="center">
                    {utils.parseDateLocaleFormat(
                      JSON.parse(item?.descripcionServicio)?.finalDate
                    )}
                  </Text>
                </Box>
              </Flex>
              <Text fw={700} c="#004236">
                {utils.formatter.format(item?.Valor)}
              </Text>
            </Box>
          </Flex>
        </GlobalCard>
      ))}
    </Box>
  );
};
