import React from "react";
import { Box, Text, Flex, Button, Grid, LoadingOverlay } from "@mantine/core";
import { ConfirmHotel } from "./confirmHotel.component";
import { ConfirmFlight } from "./confirmFlight.component";
import { ConfirmAid } from "./confirmAid.component";
import { TravelerForm } from "./travelerForm.component";
import { CompanionList } from "./companionList.component";
import { ConfirmService } from "./confirmService.component";
export const TravelerCardComponent = ({
  data,
  utils,
  isMd,
  formUser,
  classes,
  loading,
  showService,
  getAmbulanceData,
  allServicesBpo,
  accompanyingCheck,
  setAccompanyingCheck,
  accompanyingFilteredData,
  beneficiaryData,
  frequentTravelerAirlines,
  disabledValidation,
  inputValues,
  handleChangeInputs,
  formDataStep,
  GlobalCard,
  StepperComponent,
  fetchUserAdditionalInfoAddEdit,
  getVehicleData,
  getAttendanceData,
  validationData,
  getFlightData,
  getHotelData,
}) => {
  const companionListProps = {
    utils,
    classes,
    formUser,
    accompanyingCheck,
    setAccompanyingCheck,
    accompanyingFilteredData,
    beneficiaryData,
  };

  const travelerFormProps = {
    data,
    frequentTravelerAirlines,
    disabledValidation,
    utils,
    formUser,
    inputValues,
    handleChangeInputs,
    formDataStep,
    GlobalCard,
    classes,
  };

  const confirmAidProps = {
    data: {
      getAmbulanceData: getAmbulanceData,
      travelData: data?.travelLiquidatorData?.ViajeTrayecto?.[0],
    },
    GlobalCard,
    classes,
    utils,
  };

  const confirmHotelProps = {
    hotelData: getHotelData,
    isMd,
    utils,
    GlobalCard,
  };

  const confirmFlightProps = {
    data: getFlightData,
    validationData,
    GlobalCard,
    utils,
    isMd,
  };

  const confirmAttendanceProps = {
    data: getAttendanceData,
    GlobalCard,
    isMd,
    utils,
    title: "Asistencia Internacional",
  };

  const confirmVehicleProps = {
    data: getVehicleData,
    isMd,
    utils,
    GlobalCard,
    title: "Alquiler Vehículo Internacional",
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
      <StepperComponent servicesData={data?.travelLiquidatorData} />
      {formDataStep === 2 &&
        data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
          ?.Acompanante && <CompanionList {...companionListProps} />}

      {formDataStep === 0 && (
        <TravelerForm
          {...travelerFormProps}
          titleForm="Confirmación datos del viajero"
        />
      )}

      {formDataStep === 1 && (
        <TravelerForm
          titleForm="Confirmación datos del beneficiario"
          {...travelerFormProps}
        />
      )}
      {formDataStep === 2 &&
        data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
          ?.Acompanante && (
          <TravelerForm
            titleForm="Confirmación datos del acompañante"
            {...travelerFormProps}
          />
        )}

      <Grid>
        <>
          {!allServicesBpo.filter(({ Valor }) => Valor > 0).length ? null : (
            <Grid.Col span={12}>
              <Text component="h2" fw="bold" color="#004236" fz={24}>
                Servicios seleccionados
              </Text>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={12}>
                {!!getAmbulanceData?.length && (
                  <ConfirmAid {...confirmAidProps} />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                {showService("HOTEL") && (
                  <ConfirmHotel {...confirmHotelProps} />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                {showService("AVION") && (
                  <ConfirmFlight {...confirmFlightProps} />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                {showService("ASISMED") && (
                  <ConfirmService {...confirmAttendanceProps} />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                {showService("ALQVEH") && (
                  <ConfirmService {...confirmVehicleProps} />
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </>
      </Grid>
      <Flex gap={12}>
        <Button
          fullWidth
          className={classes.button}
          data-testid="travelerCard-button"
          onClick={fetchUserAdditionalInfoAddEdit}
          disabled={!formUser.isValid()}
        >
          Confirmar
        </Button>
      </Flex>
    </Box>
  );
};
