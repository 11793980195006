import React from "react";
import { Text, TextInput, Flex, Grid, Box } from "@mantine/core";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

export const ConfirmAid = ({ data, GlobalCard, classes, utils }) => {
  const getAmbulanceData = JSON?.parse(
    data?.getAmbulanceData[0]?.descripcionServicio
  );

  const ambulanceData = [
    {
      checked: getAmbulanceData?.basicCareTransfer,
      name: "Traslado asistencial básico",
    },
    {
      checked: getAmbulanceData?.medicalizedCareTransfer,
      name: "Traslado asistencial medicalizado",
    },
    { checked: getAmbulanceData?.withCompanion, name: "Con acompañante" },
    {
      checked: getAmbulanceData?.ambulanceAvailability,
      name: "Disponibilidad de ambulancia",
    },
  ];

  return (
    <Box>
      <Text component="h3" fw={700} size="xl" color="#004236" mt={0}>
        Ambulancia
      </Text>

      <GlobalCard>
        <Grid>
          <Grid.Col span={12}>
            <Text size="lg" color="#004236" fw={500}>
              Trayecto Ambulancia
            </Text>
          </Grid.Col>
          <Grid.Col xs={12} md={4}>
            <TextInput
              label="Ciudad de origen"
              value={getAmbulanceData?.origin?.split("/")[2]}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={4}>
            <TextInput
              label="Ciudad de destino"
              value={getAmbulanceData?.destination?.split("/")[2]}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={4}>
            <TextInput
              label="Fechas"
              value={`${utils.parseDateLocaleFormat(
                data?.travelData?.FechaInicio
              )} - ${utils.parseDateLocaleFormat(data?.travelData?.FechaFin)}`}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              disabled={true}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Flex
              justify="center"
              gap={12}
              align="center"
              sx={(theme) => ({
                [theme.fn.smallerThan("md")]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              })}
            >
              {ambulanceData?.map((item) => (
                <Flex key={item?.name} align="center" gap={8}>
                  {item.checked === "true" ? (
                    <FaCheckCircle color="#004236" size="1rem" />
                  ) : (
                    <FaPlus
                      color="#c00"
                      size="1rem"
                      style={{
                        transform: "rotate(45deg)",
                      }}
                    />
                  )}
                  <Text fw={500} color="#004236">
                    {item?.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Grid.Col>
        </Grid>
      </GlobalCard>
    </Box>
  );
};
