import { useState, useEffect } from "react";
import {
  useStyles,
  StepperComponent,
  useWindowSize,
  GlobalCard,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  fetchTraveLiquidatorByIDData,
  parseDateLocaleFormat,
  lodash,
  fetchCountriesData,
  getAidBeneficiariesData,
  getAge,
  es,
  fetchFrequentTravelerServicesData,
  fetchFrequentTravelerData,
  moment,
  fetchUserAdditionalInfoByID,
  fetchUserAdditionalInfoUpdateCreate,
  fetchUserSummary,
  parseDateWihtFormat,
  fetchFrequentTravelerUpdateCreate,
  extractTime,
  formatter,
  fetchTraveLiquidatorUpdateData,
} from "@mfe/ts-common-ave-utilitaries";
import { navigateToUrl } from "single-spa";
import { TravelerCardComponent } from "../components/travelerCard.component";

export default function TravelerDetails({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <TravelerDetailsComponent organization={organization} />
    </MsalProvider>
  );
}

export const TravelerDetailsComponent = ({ organization }) => {
  const { classes } = useStyles();
  const [tokenApi, setTokenApi] = useState("");
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const isMd = useWindowSize("md");
  const userAidData =
    JSON.parse(localStorage.getItem("aidBeneficiaryData")) ?? null;
  const [beneficiaryData, setBeneficiaryData] = useState([]);

  const [accompanyingCheck, setAccompanyingCheck] = useState(null);

  const [data, setData] = useState<any>({
    travelLiquidatorData: {},
    countriesData: [],
    frequentServices: [],
    frequentTraveler: [],
    aditionalData: {},
  });

  const [inputValues, setInputValues] = useState([]);
  const [formDataStep, setFormDataStep] = useState(0);

  const validationData =
    String(data?.travelLiquidatorData?.TipoProceso) === "1" ||
    data?.travelLiquidatorData?.TipoViajeAlias !== "TVINTERNCL";

  const allServicesBpo = lodash.flatMap(
    data?.travelLiquidatorData?.ViajeTrayecto,
    ({ ServicioBpo }) => ServicioBpo
  );

  const getHotelData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "HOTEL" && Valor > 0
  );

  const getFlightData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "AVION" && Valor > 0
  );

  const getAttendanceData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "ASISMED" && Valor > 0
  );

  const getVehicleData = allServicesBpo.filter(
    ({ AliasServicio, Valor }) => AliasServicio === "ALQVEH" && Valor > 0
  );

  const getAmbulanceData = allServicesBpo.filter(
    ({ AliasServicio }) => AliasServicio === "AMBULANCIA"
  );

  const showService = lodash.memoize((alias) => {
    return lodash.some(
      allServicesBpo,
      (service) => service.Valor > 0 && service.AliasServicio === alias
    );
  });

  const accompanyingFilteredData = beneficiaryData?.filter(
    (item) =>
      item.id !== userAidData?.userSelected?.doc && getAge(item?.fechaNac) >= 18
  );

  const disabledValidation = () => {
    return formDataStep === 2 ? false : true;
  };

  const travelUserCodId = data?.travelLiquidatorData?.CodigoEmpleadoViajero;

  const isAidProcessType = data?.travelLiquidatorData?.TipoProceso === 4;

  const validateEmail = (value) =>
    /^\S+@\S+$/.test(value) ? null : "Ingrese un email valido";

  const validateEmailType = (value) =>
    !!Object?.keys(userAidData || {})?.length
      ? value
        ? null
        : "Ingrese un valor."
      : validateEmail(value);

  const formUser = useForm({
    validateInputOnChange: true,
    initialValues: {
      nombres: "",
      apellidos: "",
      tipoDocumento: "",
      numeroDocumento: "",
      correoElectronico: "",
      fechaNacimiento: null,
      telefono: "",
      genero: "",
      ciudadResidencia: "",
      direccion: "",
      nacionalidad: "",
      nombreEmergencia: "",
      numeroTelefonoEmergencia: "",
      nombre: "",
    },
    validate: {
      nombres: (value) => (value ? null : "Ingrese un nombre."),
      tipoDocumento: (value) =>
        value ? null : "Ingrese un tipo documento de identificación.",
      numeroDocumento: (value) =>
        value ? null : "Ingrese su documento de identificación.",
      correoElectronico: (value) => validateEmailType(value),
      fechaNacimiento: (value) =>
        value ? null : "Ingrese una fecha de nacimiento",
      telefono: (value) =>
        value?.length > 0
          ? /^[0-9]*$/.test(value)
            ? null
            : "Si lo posee, escriba un número válido."
          : "Ingrese un número de teléfono.",
      genero: (value) => (value ? null : "Seleccione un género."),
      ciudadResidencia: (value) => (value ? null : "Selecciones una ciudad."),
      direccion: (value) => (value ? null : "Ingrese una dirección."),
      nacionalidad: (value) => (value ? null : "Seleccione una nacionalidad."),
      nombreEmergencia: (value) =>
        value ? null : "Ingrese el nombre del contacto de emergencia.",
      numeroTelefonoEmergencia: (value) =>
        value?.length > 0
          ? /^[0-9]*$/.test(value)
            ? null
            : "Si lo posee, escriba un número válido."
          : "Ingrese un número de teléfono.",
    },
  });

  const navigateValidation = async () => {
    if (!isAidProcessType) {
      navigateToUrl("/reserva-detalles");
    } else {
      if (
        formDataStep === 2 ||
        !data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
          ?.Acompanante
      ) {
        try {
          setLoading(true);
          await fetchUserAdditionalInfoUpdateCreate(tokenApi, {
            viajeId: localStorage.getItem("liquidationID"),
            tipo: "TITULAR",
            codigoEmpleado: travelUserCodId,
            ...{
              nombres: userAidData?.holder?.nombres,
              apellidos: userAidData?.holder?.apellidos,
              tipoDocumento: userAidData?.holder?.tipoDoc,
              numeroDocumento: userAidData?.holder?.doc,
              correoElectronico: userAidData?.holder?.email,
            },
          });
          navigateToUrl("/reserva-detalles");
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        formUser.reset();
        window.scrollTo(0, 0);
        setFormDataStep(2);
      }
    }
  };

  const fetchUserAdditionalInfoAddEdit = async () => {
    try {
      setLoading(true);
      await fetchUserAdditionalInfoUpdateCreate(tokenApi, {
        viajeId: localStorage.getItem("liquidationID"),
        tipo: !isAidProcessType
          ? "VIAJERO"
          : formDataStep === 1
          ? "BENEFICIARIO"
          : "ACOMPANANTE",
        codigoEmpleado: travelUserCodId,
        ...formUser?.values,
        nombres: formUser.values.nombre,
      });
      for (let index = 0; index < inputValues.length; index++) {
        const element = inputValues?.[index];
        if (element.id.length > 0) {
          await fetchFrequentTravelerUpdateCreate(tokenApi, {
            employeeCode: travelUserCodId,
            ...element,
          });
        }
      }
      if (formDataStep === 2)
        await fetchTraveLiquidatorUpdateData(
          tokenApi,
          {
            ...data?.travelLiquidatorData,
            ViajeTrayecto: [
              ...[
                {
                  ...data?.travelLiquidatorData?.ViajeTrayecto?.[0],
                  auxilioMedico: {
                    ...data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                      ?.auxilioMedico,
                    AcompananteTitular:
                      formUser.values.numeroDocumento ===
                        userAidData?.holder?.doc && accompanyingCheck !== null,
                  },
                },
              ],
              ...data?.travelLiquidatorData?.ViajeTrayecto?.slice(1),
            ],
          },
          setLoading
        );
      navigateValidation();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const frequentTravelerAirlines = () => {
    const listOfAirlines = [];
    const airlines = getFlightData?.map((item) => {
      const description = JSON.parse(item.descripcionServicio)?.fligths?.[0];
      return {
        code: description?.codeAirLine,
        name: description?.nameAirLine,
        id: "",
      };
    });

    const frequentAirlines = data?.frequentTraveler
      ?.filter((item) =>
        airlines.some((airline) => airline.code === item.codigoAerolinea)
      )
      ?.map((item) => ({
        code: item?.codigoAerolinea,
        name: item?.nombreAerolinea,
        id: item?.numViajeroFrecuente,
      }));

    const allAirlines = [...frequentAirlines, ...airlines];

    allAirlines.forEach((airline) => {
      if (!listOfAirlines.some((item) => item.code === airline.code)) {
        listOfAirlines.push(airline);
      }
    });

    return listOfAirlines;
  };

  useEffect(() => {
    if (frequentTravelerAirlines().length > 0) {
      setInputValues(frequentTravelerAirlines());
    }
  }, [JSON.stringify(frequentTravelerAirlines())]);

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );

      fetchCountriesData(setData, tokenApi);
    }
  }, [tokenApi]);

  const fetchDataUserForm = async () => {
    const res = await fetchUserAdditionalInfoByID(tokenApi, travelUserCodId);
    if (isAidProcessType) {
      getAidBeneficiariesData(
        setBeneficiaryData,
        tokenApi,
        userAidData?.holder?.registro,
        setLoading
      );
      if (
        !allServicesBpo?.length &&
        data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
          ?.Acompanante
      ) {
        setFormDataStep(2);
      } else {
        setFormDataStep(1);
      }
      formUser.setValues({
        nombres: `${userAidData?.userSelected?.nombres} ${userAidData?.userSelected?.apellidos}`,
        nombre: userAidData?.userSelected?.nombres,
        apellidos: userAidData?.userSelected?.apellidos,
        tipoDocumento: userAidData?.userSelected?.tipoDoc,
        numeroDocumento: userAidData?.userSelected?.doc,
        correoElectronico: userAidData?.userSelected?.email,
        fechaNacimiento: moment(
          parseDateWihtFormat(
            userAidData?.userSelected?.fechaNac,
            "DD/MMM/YYYY"
          )
        ),
        nacionalidad: data?.nacionalidad || res?.data?.nacionalidad,
        telefono: res?.data?.telefono,
        genero: res?.data?.genero,
        ciudadResidencia: res?.data?.ciudadResidencia,
        direccion: res?.data?.direccion,
        nombreEmergencia: res?.data?.nombreEmergencia,
        numeroTelefonoEmergencia: res?.data?.numeroTelefonoEmergencia,
      });
    } else {
      const { data } = await fetchUserSummary(tokenApi, travelUserCodId);
      formUser.setValues({
        nombres: data?.nombre,
        nombre: data?.nombres,
        apellidos: data?.apellidos,
        tipoDocumento: data?.tipoDocumento,
        numeroDocumento: data?.identificacion,
        correoElectronico: data?.correoElectronico,
        fechaNacimiento: moment(data?.fechaNacimiento, "DD/MM/YYYY"),
        nacionalidad: data?.nacionalidad || res?.data?.nacionalidad,
        telefono: res?.data?.telefono,
        genero: res?.data?.genero,
        ciudadResidencia: res?.data?.ciudadResidencia,
        direccion: res?.data?.direccion,
        nombreEmergencia: res?.data?.nombreEmergencia,
        numeroTelefonoEmergencia: res?.data?.numeroTelefonoEmergencia,
      });
    }
  };

  const filterAccompanyingByIndex = accompanyingFilteredData?.filter(
    (item, index) => index === accompanyingCheck
  );

  useEffect(() => {
    if (!!filterAccompanyingByIndex.length) {
      formUser.reset();
      const filterData = filterAccompanyingByIndex[0];
      formUser.setValues({
        nombres: filterData?.nombresApellidos,
        nombre: filterData?.nombres,
        apellidos: filterData?.apellidos,
        tipoDocumento: filterData?.tipoDoc,
        numeroDocumento: filterData?.doc,
        correoElectronico: filterData?.email,
        fechaNacimiento: moment(
          parseDateWihtFormat(filterData?.fechaNac, "DD/MMM/YYYY")
        ),
      });
    }
  }, [filterAccompanyingByIndex[0]]);

  useEffect(() => {
    if (formDataStep === 2) {
      formUser.reset();
    }
  }, [formDataStep]);
  useEffect(() => {
    if (formDataStep === 2 && formUser.values.nombre) {
      formUser.setFieldValue("nombres", formUser.values.nombre);
    }
  }, [formDataStep, formUser.values.nombre]);

  useEffect(() => {
    if (data?.travelLiquidatorData?.CodigoEmpleadoViajero) {
      fetchDataUserForm();

      if (showService("AVION")) {
        fetchFrequentTravelerServicesData(setData, tokenApi);
        fetchFrequentTravelerData(
          setData,
          tokenApi,
          data?.travelLiquidatorData?.CodigoEmpleadoViajero
        );
      }
    }
  }, [data?.travelLiquidatorData]);

  const handleChangeInputs = (index, value) => {
    const updatedList = [...inputValues];
    updatedList[index]["id"] = value;
    setInputValues(updatedList);
  };

  const utils = {
    es,
    getAge,
    formatter,
    extractTime,
    parseDateWihtFormat,
    parseDateLocaleFormat,
  };

  const travelerCardComponentProps = {
    data,
    utils,
    isMd,
    formUser,
    classes,
    loading,
    showService,
    getAmbulanceData,
    allServicesBpo,
    accompanyingCheck,
    setAccompanyingCheck,
    accompanyingFilteredData,
    beneficiaryData,
    frequentTravelerAirlines,
    disabledValidation,
    inputValues,
    handleChangeInputs,
    formDataStep,
    GlobalCard,
    StepperComponent,
    fetchUserAdditionalInfoAddEdit,
    getVehicleData,
    getAttendanceData,
    validationData,
    getFlightData,
    getHotelData,
  };

  return isAuthenticated ? (
    <TravelerCardComponent {...travelerCardComponentProps} />
  ) : null;
};
