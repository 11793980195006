import React from "react";
import { Box, Text, Flex, Checkbox } from "@mantine/core";
import { startCase, camelCase } from "lodash";

export const CompanionList = ({
  accompanyingCheck,
  setAccompanyingCheck,
  formUser,
  utils,
  accompanyingFilteredData,
  beneficiaryData,
  classes,
}) => {
  return (
    beneficiaryData?.length > 0 &&
    accompanyingFilteredData?.map((beneficiary, index) => (
      <React.Fragment key={index}>
        {index === 0 && (
          <Text fz={24} fw={700} color="#004236" py={16} component="h1" px={8}>
            Acompañante
          </Text>
        )}
        <Box mb={12} px={8}>
          <Text fw={700} color="#004236">
            {startCase(
              camelCase(`${beneficiary?.nombres} ${beneficiary?.apellidos}`)
            )}
          </Text>
          <Flex gap={8} wrap="wrap">
            <Text>CC: {beneficiary?.doc}</Text>
            <Text>Edad: {utils.getAge(beneficiary?.fechaNac)} años</Text>
            <Text>
              {`Fecha de nacimiento: ${utils.parseDateLocaleFormat(
                beneficiary?.fechaNac
              )}`}
            </Text>
            <Text>Género: {beneficiary?.genero}</Text>
            <Text>Ciudad: {beneficiary?.ciudad}</Text>
          </Flex>
          <Checkbox
            mt={8}
            label="Seleccionar como acompañante"
            className={classes.checkBox}
            data-testid="checkBox-companion"
            checked={index === accompanyingCheck}
            onChange={() => {
              if (index === accompanyingCheck) {
                setAccompanyingCheck(null);
                formUser.setValues({
                  nombres: "",
                  nombre: "",
                  apellidos: "",
                  tipoDocumento: "",
                  numeroDocumento: "",
                  correoElectronico: "",
                  fechaNacimiento: null,
                });
              } else {
                setAccompanyingCheck(index);
              }
            }}
          />
        </Box>
      </React.Fragment>
    ))
  );
};
